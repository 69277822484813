import React, { useEffect, useState } from 'react';
import IconPhone from '../assets/icons/sp_phone.png';
import IconMess from '../assets/icons/sp_messenger.png';
import IconZalo from '../assets/icons/sp_zalo.png';
import IconCancel from '../assets/icons/sp_deleteall.png';
import '../styles/layout/FloatingButton.scss';
import { UpOutlined, UpSquareOutlined } from '@ant-design/icons';

const FloatingButton = (props: any) => {
  const { contact } = props;
  const [ data, setData ] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isShowUp, setIsShowUP] = useState(false);

  const handleGotoLinkZalo = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      return data.link_zalo_mobile;
    } 
    return data.link_zalo;
  }

  useEffect(() => {
    setData(contact);
  }, [contact]);

  const toggleFunction = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 10) {
        setIsShowUP(true);
      } else {
        setIsShowUP(false);
      }
    });
  }, [window.scrollY]);

  return (
    <>
      <UpOutlined className={isShowUp ? 'go-up' : 'hidden'} onClick={() => {
        // smooth scroll to top
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }} />
      <div className="support">
        <div className="row">
            <div className="col-6 support-sdt support-item p-0 support-item-hidden">
                <a id="support-sdt" href={`tel:${data.sdt}`}>
                  <img src={IconPhone} alt="sp_phone" />
                </a>
            </div>
            <div className="col-6 support-zalo p-0 support-item support-item-hidden">
                <a 
                  href={
                    handleGotoLinkZalo()
                  }
                id="support-zalo" target="_blank">
                  <img src={IconZalo} alt="sp_zalo" />
                </a>
            </div>
            <div className="col-6 support-mess p-0 support-item support-item-hidden">
                <a id="support-mess" target="_blank" href={data.link_facebook}>
                  <img src={IconMess} alt="sp_messenger" />
                </a>
            </div>
            <div className="col-6 support-delete p-0 support-item support-item-hidden"
                onClick={() => {
                  var supportItems = document.getElementsByClassName('support-item');
                  document.getElementsByClassName('support')[0].classList.remove('support_after');

                  for (var i = 0; i < supportItems.length; i++) {
                      supportItems[i].classList.add('support-item-hidden');
                      supportItems[i].classList.remove('support-item-show');
                  }

                  var supportUser = document.getElementsByClassName('support-user')[0];
                  supportUser.classList.add('support-user-show');
                  supportUser.classList.remove('support-user-hidden');
                }}
            >
                <img src={IconCancel} alt="sp_deleteall" />
            </div>
            <div className="support-user support-user-show"
                onClick={(e) => {
                  var supportItems = document.getElementsByClassName('support-item');
                  document.getElementsByClassName('support')[0].classList.add('support_after');

                  e.currentTarget.classList.add('support-user-hidden');
                  e.currentTarget.classList.remove('support-user-show');

                  for (var i = 0; i < supportItems.length; i++) {
                      supportItems[i].classList.remove('support-item-hidden');
                      supportItems[i].classList.remove('support-item-show');
                  }
                }}
              >
                <div className="coccoc-alo-phone coccoc-alo-green coccoc-alo-show">
                    <div className="coccoc-alo-ph-circle"></div>
                    <div className="coccoc-alo-ph-circle-fill"></div>
                    <div className="coccoc-alo-ph-img-circle"></div>
                </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default FloatingButton;