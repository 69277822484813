import { IResponse, Obj } from '../interfaces/common/common';
import { REQUEST_METHOD } from '../utils/constants/enum';
import api from './api';
import { AUTHENTICATION_FAIL_CODE, ERR_NETWORK, NOT_ALLOW_CODE } from '../utils/constants/https';
import { KEY } from 'src/utils/configs/app';

const handleApiError = (error: any) => {
  const responseError = error?.response?.data;
  let status = error?.response?.status || 500;
  let message = responseError?.error?.message ? responseError?.error?.message : error?.message;

  if (status === AUTHENTICATION_FAIL_CODE || status === NOT_ALLOW_CODE || error?.code === ERR_NETWORK) {
    return {
      data: null,
      status: status,
      message: message,
    };
  }

  return {
    isError: true,
    data: null,
    status: status,
    message: message,
  } as IResponse;
};

export const ApiRequest = async (method: REQUEST_METHOD, endpoint: string, data?: Obj, params?: any, responseType?: any) => {
  try {
    const response = await api({
      method,
      url: endpoint,
      data,
      params,
      responseType,
    });

    if (response.status !== 200) {
      return handleApiError(response);
    }

    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};
