import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../utils/constants/routes';
import { Spin } from 'antd';
import DefaultLayout from '../../layout/DefaultLayout';

export const publicRouter = [
  {
    path: ROUTES.HOME,
    element: lazy(() => import('../../pages/Home')),
    auth: false,
  },
  {
    path: '/',
    element: lazy(() => import('../../pages/Redirect')),
    auth: false,
  },
  {
    path: ROUTES.DETAIL_SERVICE,
    element: lazy(() => import('../../pages/DetailService')),
    auth: false,
  },
  {
    path: ROUTES.CONTACT,
    element: lazy(() => import('../../pages/Contact')),
    auth: false,
  },
  {
    path: ROUTES.MEMBERS,
    element: lazy(() => import('../../pages/Members')),
    auth: false,
  },
  {
    path: ROUTES.MEMBER,
    element: lazy(() => import('../../pages/Member')),
    auth: false,
  },
  {
    path: ROUTES.NEWS,
    element: lazy(() => import('../../pages/News')),
    auth: false,
  },
  {
    path: ROUTES.NEW,
    element: lazy(() => import('../../pages/DetailNew')),
    auth: false,
  },
  {
    path: ROUTES.INTRO,
    element: lazy(() => import('../../pages/Intro')),
    auth: false,
  },
];


const ConfigRouter: React.FC = () => {
  return (
    <Routes>
      <Route element={<DefaultLayout />}>
        {publicRouter.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <React.Suspense
                fallback={
                  <Spin spinning wrapperClassName="spinning-route">
                    <div
                      style={{
                        width: '100vw',
                        height: '100vh',
                      }}
                    />
                  </Spin>
                }
              >
                {<route.element />}
              </React.Suspense>
            }
          />
        ))}
      </Route>
    </Routes>
  );
};

export default ConfigRouter;
