import React, { createContext, ReactNode, useState, Dispatch, SetStateAction, useEffect } from 'react';

interface GlobalContextProps {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const GlobalContext = createContext<GlobalContextProps | undefined>({
  loading: false,
  setLoading: () => { }
});

interface GlobalContextProviderProps {
  children: ReactNode;
}

const GlobalContextProvider: React.FC<GlobalContextProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
  }, []);

  return <GlobalContext.Provider value={{
    loading, setLoading,
  }}
  >
    {children}
  </GlobalContext.Provider>;
};

export { GlobalContext, GlobalContextProvider };
