import React from 'react';
import './App.css';
import GlobalStyles from './styles/GlobalStyles/GlobalStyles';
import './styles/bases/Responsive.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { GlobalContextProvider } from './context/GlobalContext/GlobalContext';
import Loading from './components/Loading';
import ConfigRouter from './context/configRouter/configRouter';

interface ExtendedAliasToken {
  fontFamily?: string;
  fontSize?: number;
  color?: string; // Thêm thuộc tính màu sắc
}

function App() {
  return (
    <React.Fragment>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Inter, sans-serif',
            fontSize: 14,
            color: '#496057',
          } as ExtendedAliasToken,
        }}
      >
          <Router>
            <ConfigProvider>
              <GlobalContextProvider>
                <div className="App">
                  <Loading>
                    <GlobalStyles>
                      <div className="container">
                        <ConfigRouter></ConfigRouter>
                      </div>
                    </GlobalStyles>
                  </Loading>
                </div>
              </GlobalContextProvider>
            </ConfigProvider>
          </Router>
      </ConfigProvider>
    </React.Fragment>
  );
}

export default App;
