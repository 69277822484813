import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { KEY } from '../utils/configs/app';
import { ERR_NETWORK } from 'src/utils/constants/https';

const instance: AxiosInstance = axios.create({
  baseURL: KEY.BASE_URL,
});

instance.interceptors.request.use(
  (config: AxiosRequestConfig | any) => {

    config.headers = {
      ...config.headers,
      'Content-Type': 'application/json',
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default instance;
