import { KEY } from '../utils/configs/app';

export const PATH = {
  SERVICES: '/v1/services', 
  SERVICE: '/v1/service/:slug', 
  WHYS: '/v1/whys', 
  BANNER: '/v1/banner', 
  YEU_CAU_TU_VAN: '/v1/yeu-cau',
  NEWS: '/v1/news',
  NEW: '/v1/news/:slug',
  NEWS_LIST_ALL_TYPE: '/v1/news-all',
  INFORMATION_CONTACT: '/v1/information-contact',
  INTRO: '/v1/gioi-thieu',
  CONTACT: '/v1/lien-he',
  MEMBERS: '/v1/nhan-vien',
  MEMBER: '/v1/nhan-vien/:slug',
  REVIEWS: '/v1/reviews',
};
