import React, { ReactNode, useEffect, useState } from 'react';
import '../styles/layout/DefaultLayout.scss';
import Header from './Header';
import { Outlet, useNavigate } from 'react-router-dom';
import Footer from './Footer';
import { ApiRequest } from 'src/api/apiRequest';
import { REQUEST_METHOD } from 'src/utils/constants/enum';
import { PATH } from 'src/services/Path';
import { ROUTES } from 'src/utils/constants/routes';
import FloatingButton from '../layout/FloatingButton';
import { useStoreGlobal } from 'src/hooks/useStoreGlobal';
import '../styles/components/Toc.scss';

const DefaultLayout = () => {
  const { setLoading } = useStoreGlobal();
  const navigate = useNavigate();
  const [services, setServices] = useState<any[]>([]);
  const [contact, setContact] = useState<any>({});
  const [colors, setColors] = useState<any>({});
  const [slogan, setSologan] = useState('');

  const _getServices = async () => {
    const response = await ApiRequest(REQUEST_METHOD.GET, PATH.SERVICES);
    if (response?.data) {
      const servicesForStore = response.data.map((service: any) => {
        const link = ROUTES.DETAIL_SERVICE.replace(':slug', service.slug);
        const icon = service?.icon[0] || {};
        const icon_image = process.env.REACT_APP_API_URL + icon?.url || '';

        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(service?.ten_dich_vu, 'text/html');
        const textValue = htmlDoc.body.textContent || "";

        return {
          ...service,
          link,
          icon_image,
          ten_dich_vu: textValue,
        };
      });
      setServices(servicesForStore);
    }
  }

  const getContact = async () => {
    const response = await ApiRequest(REQUEST_METHOD.GET, PATH.CONTACT);
    if (response?.data?.image) {
      var data = response?.data;
      var logo = data?.image?.url || '';
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(data?.ten_cong_ty, 'text/html');
      const textValue = htmlDoc.body.textContent || "";

      data = {
        ...data,
        image: process.env.REACT_APP_API_URL + logo,
        ten_cong_ty_txt: textValue,
      };
      setContact(data);
    }
  }

  const getIntro = async () => {
    const response = await ApiRequest(REQUEST_METHOD.GET, PATH.INTRO);
    if (response?.data && response?.data?.image) {
      var introDb = response?.data;
      var color_header = introDb?.common_color?.background_header || '';
      var color_body = introDb?.common_color?.background_body || '';
      var color_footer = introDb?.common_color?.background_footer || '';
      var color_font_header = introDb?.common_color?.color_font_header || '';
      var color_font_footer_title = introDb?.common_color?.color_font_footer_title || '';
      var color_font_footer_item = introDb?.common_color?.color_font_footer_item || '';

      setColors({
        background_header: color_header,
        background_body: color_body,
        background_footer: color_footer,
        color_font_header: color_font_header,
        color_font_footer_title: color_font_footer_title,
        color_font_footer_item: color_font_footer_item,
      });
    }

    if (response?.data?.slogan) {
      setSologan(response?.data?.slogan);
    }
  }

  const getWhys = async () => {
    const response = await ApiRequest(REQUEST_METHOD.GET, PATH.WHYS);
      if (response?.data) {
        const whysDb = response.data.map((item: any) => {
          return {
            noi_dung: item.noi_dung || '',
            icon_image: item?.icon?.url !== undefined ? process.env.REACT_APP_API_URL + item?.icon?.url : '',
          };
        });
        sessionStorage.setItem('whys', JSON.stringify(whysDb));
      }
  }
  
  useEffect(() => {
    setLoading(true);
    
    _getServices();
    getContact();
    getIntro();
    getWhys();

    if (window.location.hash === '') {
      window.scrollTo(0, 0);
    }

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [window.location.pathname]);


  return (
    <aside>
      <Header services={services} logo={contact?.image} color={colors} slogan={slogan}/>
      <div className="virtual-headers"></div>
      <div className="vi-container">
        <style>
          {`
            .vi-container {
              background-color: ${colors?.background_body || '#fff'};
            }
          `}
        </style>
          <Outlet />
      </div>
      <Footer services={services} contact={contact} color={colors}/>
      <FloatingButton contact={contact} />
    </aside>
  );
};

export default DefaultLayout;
