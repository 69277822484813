import React, { useContext, useEffect, useRef, useState } from 'react';
import '../styles/pages/header.scss';
import { Button, Dropdown, Menu, MenuProps, Space } from 'antd';
import { AppstoreOutlined, CaretDownOutlined, DownOutlined, MailOutlined, MenuFoldOutlined, MenuUnfoldOutlined, NotificationOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useStoreGlobal } from 'src/hooks/useStoreGlobal';
import { NavLink, useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/utils/constants/routes';
import LogoShapeFake from 'src/assets/images/logo/logo_shape.png';

// get props from parent
const Header = (props: any) => {
  const { services } = props;
  const { logo } = props;
  const { color } = props;
  const { background_header, color_font_header } = color;
  const { slogan } = props;

  const { t } = useTranslation();
  const { setLoading } = useStoreGlobal();
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(false)
  const [items, setItems] = useState<MenuProps['items']>([]);
  const [ logoShape, setLogoShape ] = useState('');

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar)
  }

  const _getServices = async () => {
    if (services.length >= 0) {
      const menuItems = services.map((service: any) => {
        const slug = service.slug;
        const link = ROUTES.DETAIL_SERVICE.replace(':slug', slug);

        return {
          key: slug,
          label: (
            <a className='ck5' rel="noopener noreferrer" href={link}>
              {service.ten_dich_vu}
            </a>
          ),
        };
      });

      setItems(menuItems);
    }
  }

  useEffect(() => {
    _getServices();
    setLogoShape( logo ?? LogoShapeFake);
  }, [services, logo, background_header]);

  return (
    <header className="header shadow-border">
      <div className="header-body">
        <nav className="navbar">
          <style>
            {`
              .header .navbar {
                background: ${background_header || '#fcaf17'} !important;
              }
              .nav-elements ul a {
                color: ${color_font_header || '#474242'} !important;
              }
            `}
          </style>
          <div className="flex justify-between align-center pl-0 pr-4 md:pl-0 md:pr-0 items-center h-100 w-full md:w-[80%]">
            <div className="logo" onClick={() => navigate(ROUTES.HOME)}>
              <img src={logoShape} className="logo logo__shape" alt="" />
              {slogan && 
                <div className='ck5' >
                  <p className='ck-content logo__text' dangerouslySetInnerHTML={{ __html: slogan }}></p>
              </div>
              }
            </div>
            <div className="menu-icon" onClick={handleShowNavbar}>
              {showNavbar ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </div>
            <div className={`nav-elements  ${showNavbar && 'active'}`}>
              <ul>
                <li>
                  <NavLink onClick={handleShowNavbar} to={ROUTES.HOME} className={`fw-600`}>Trang chủ</NavLink>
                </li>
                <li>
                  <NavLink onClick={handleShowNavbar} to={ROUTES.INTRO}>Giới Thiệu</NavLink>
                </li>
                <li>
                  <Dropdown menu={{ items }}>
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>
                        Dịch Vụ
                        <CaretDownOutlined />
                      </Space>
                    </a>
                  </Dropdown>
                </li>
                <li>
                  <NavLink onClick={handleShowNavbar} to={ROUTES.MEMBERS}>Nhân lực</NavLink>
                </li>
                <li>
                  <NavLink onClick={handleShowNavbar} to={ROUTES.NEWS}>Tin Tức</NavLink>
                </li>
                <li>
                  <NavLink onClick={handleShowNavbar} to={ROUTES.CONTACT}>Liên Hệ</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
