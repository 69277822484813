import React, { useContext, useEffect, useRef, useState } from 'react';
import '../styles/pages/header.scss';
import { AimOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useStoreGlobal } from 'src/hooks/useStoreGlobal';
import { NavLink, useNavigate } from 'react-router-dom';
import LogoShapeFake from 'src/assets/images/logo/logo_meta.png';


const Footer = (props: any) => {
  const { services, contact } = props;
  const { color } = props;
  const { background_footer, color_font_footer_title, color_font_footer_item } = color;

  const { t } = useTranslation();
  const { setLoading } = useStoreGlobal();
  const navigate = useNavigate();
  const [LogoShape, setLogo] = useState('')

  useEffect(() => {
    setLogo(contact?.image ?? LogoShapeFake);
  }, [contact, color]);

  return (
    <footer className="ent-full">
    <style>
        {`
          footer {
            background: ${background_footer || '#fcaf17'} !important;
          }
          .title-menu {
            color: ${color?.color_font_footer || '#fff'} !important;
          }

          .title-menu {
            color: ${color?.color_font_footer_title || '#fff'} !important;
          }
          .menu-item a {
            color: ${color?.color_font_footer_item || '#aa0606'} !important;
          }

          .textwidget {
            color: ${color?.color_font_footer_item || '#aa0606'} !important;
          }

          .textwidget a {
            color: ${color?.color_font_footer_item || '#aa0606'} !important;
          }

          .textwidget a:hover {
            color: blue !important;
          }
        `}
    </style>
    <div className="ent-container section-footer2">
      <div className="ent-flex">
        <div className="footer1">
          <h4 className="title-menu">
            Thông tin liên hệ
          </h4>
          <div className="textwidget">
            <li className="label-company">
              {contact?.ten_cong_ty_txt}
            </li>
            <p><AimOutlined /> {contact?.dia_chi ?? 'Đang cập nhật'} </p>
            <p><PhoneOutlined /> Hotline: <a href={`tel:${contact?.sdt}`}>{contact?.sdt ?? 'Đang cập nhật'}</a></p>
            <p><MailOutlined /> Email: {contact?.gmail ?? 'Đang cập nhật'} </p>
          </div>
        </div>
        <div className="footer3">
          <h4 className="title-menu">
            Dịch vụ
          </h4>
          <div className="textwidget">
            <div id="footer_menu" className="menu-footer-menu-tieng-viet-container">
              <ul id="menu-footer-menu-tieng-viet" className="footer_menu">
              {
                services.map((service: any, index: number) => (
                  <li key={index} className="menu-item menu-item-type-post_type menu-item-object-post menu-item-924">
                    <NavLink to={service.link}>{service.ten_dich_vu}</NavLink>
                  </li>
                ))
              }
             </ul>
            </div>                
          </div>
        </div>
        <div className="footer2">
          <h4 className="title-menu">
            Về Chúng Tôi
          </h4>
          <div className="textwidget">
            <div id="vct_menu" className="menu-ve-chung-toi-tieng-viet-container">
              <ul id="menu-ve-chung-toi-tieng-viet" className="vct_menu">
                <li id="menu-item-134" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-134"><a href="/nhan-luc" data-wpel-link="internal">Giới Thiệu</a></li>
                <li id="menu-item-134" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-134"><a href="/home#my-service" data-wpel-link="internal">Dịch Vụ</a></li>
                <li id="menu-item-134" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-134"><a href="/nhan-luc" data-wpel-link="internal">Nhân Lực</a></li>
                <li id="menu-item-136" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-136"><a href="/tin-tuc" data-wpel-link="internal">Tin Tức</a></li>
                <li id="menu-item-137" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-137"><a href="/lien-he" data-wpel-link="internal">Liên Hệ</a></li>
              </ul>
            </div>                
          </div>
        </div>
        <div className="footer2">
          <h4 className="title-menu">
            Kết Nối
          </h4>
          <div className="textwidget">
            <span className="w-full">
              <a rel="nofollow external noopener noreferrer" target="_blank" href={contact?.link_zalo} className='float-left' title="Facebook" data-wpel-link="external">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 48 48">
                  <path fill="#2962ff" d="M15,36V6.827l-1.211-0.811C8.64,8.083,5,13.112,5,19v10c0,7.732,6.268,14,14,14h10	c4.722,0,8.883-2.348,11.417-5.931V36H15z"></path><path fill="#eee" d="M29,5H19c-1.845,0-3.601,0.366-5.214,1.014C10.453,9.25,8,14.528,8,19	c0,6.771,0.936,10.735,3.712,14.607c0.216,0.301,0.357,0.653,0.376,1.022c0.043,0.835-0.129,2.365-1.634,3.742	c-0.162,0.148-0.059,0.419,0.16,0.428c0.942,0.041,2.843-0.014,4.797-0.877c0.557-0.246,1.191-0.203,1.729,0.083	C20.453,39.764,24.333,40,28,40c4.676,0,9.339-1.04,12.417-2.916C42.038,34.799,43,32.014,43,29V19C43,11.268,36.732,5,29,5z"></path><path fill="#2962ff" d="M36.75,27C34.683,27,33,25.317,33,23.25s1.683-3.75,3.75-3.75s3.75,1.683,3.75,3.75	S38.817,27,36.75,27z M36.75,21c-1.24,0-2.25,1.01-2.25,2.25s1.01,2.25,2.25,2.25S39,24.49,39,23.25S37.99,21,36.75,21z"></path><path fill="#2962ff" d="M31.5,27h-1c-0.276,0-0.5-0.224-0.5-0.5V18h1.5V27z"></path><path fill="#2962ff" d="M27,19.75v0.519c-0.629-0.476-1.403-0.769-2.25-0.769c-2.067,0-3.75,1.683-3.75,3.75	S22.683,27,24.75,27c0.847,0,1.621-0.293,2.25-0.769V26.5c0,0.276,0.224,0.5,0.5,0.5h1v-7.25H27z M24.75,25.5	c-1.24,0-2.25-1.01-2.25-2.25S23.51,21,24.75,21S27,22.01,27,23.25S25.99,25.5,24.75,25.5z"></path><path fill="#2962ff" d="M21.25,18h-8v1.5h5.321L13,26h0.026c-0.163,0.211-0.276,0.463-0.276,0.75V27h7.5	c0.276,0,0.5-0.224,0.5-0.5v-1h-5.321L21,19h-0.026c0.163-0.211,0.276-0.463,0.276-0.75V18z"></path>
                </svg>
              </a>
              <a rel="nofollow" target="_blank" href={'mailto:' + contact?.gmail} title="Gmail" className='float-left ml-4' data-wpel-link="external">
                <svg width="24px" height="24px" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 11.9556C2 8.47078 2 6.7284 2.67818 5.39739C3.27473 4.22661 4.22661 3.27473 5.39739 2.67818C6.7284 2 8.47078 2 11.9556 2H20.0444C23.5292 2 25.2716 2 26.6026 2.67818C27.7734 3.27473 28.7253 4.22661 29.3218 5.39739C30 6.7284 30 8.47078 30 11.9556V20.0444C30 23.5292 30 25.2716 29.3218 26.6026C28.7253 27.7734 27.7734 28.7253 26.6026 29.3218C25.2716 30 23.5292 30 20.0444 30H11.9556C8.47078 30 6.7284 30 5.39739 29.3218C4.22661 28.7253 3.27473 27.7734 2.67818 26.6026C2 25.2716 2 23.5292 2 20.0444V11.9556Z" fill="white"/>
                  <path d="M22.0515 8.52295L16.0644 13.1954L9.94043 8.52295V8.52421L9.94783 8.53053V15.0732L15.9954 19.8466L22.0515 15.2575V8.52295Z" fill="#EA4335"/>
                  <path d="M23.6231 7.38639L22.0508 8.52292V15.2575L26.9983 11.459V9.17074C26.9983 9.17074 26.3978 5.90258 23.6231 7.38639Z" fill="#FBBC05"/>
                  <path d="M22.0508 15.2575V23.9924H25.8428C25.8428 23.9924 26.9219 23.8813 26.9995 22.6513V11.459L22.0508 15.2575Z" fill="#34A853"/>
                  <path d="M9.94811 24.0001V15.0732L9.94043 15.0669L9.94811 24.0001Z" fill="#C5221F"/>
                  <path d="M9.94014 8.52404L8.37646 7.39382C5.60179 5.91001 5 9.17692 5 9.17692V11.4651L9.94014 15.0667V8.52404Z" fill="#C5221F"/>
                  <path d="M9.94043 8.52441V15.0671L9.94811 15.0734V8.53073L9.94043 8.52441Z" fill="#C5221F"/>
                  <path d="M5 11.4668V22.6591C5.07646 23.8904 6.15673 24.0003 6.15673 24.0003H9.94877L9.94014 15.0671L5 11.4668Z" fill="#4285F4"/>
                </svg>
              </a>
              <a rel="nofollow external noopener noreferrer" target="_blank" href={contact?.link_facebook} className='float-left ml-4' title="Facebook" data-wpel-link="external">
                <svg width="22px" height="22px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill="#0866ff" fillRule="evenodd" d="M18.8961111,0 L1.10388889,0 C0.494166667,0 0,0.494166667 0,1.10388889 L0,18.8963889 C0,19.5058333 0.494166667,20 1.10388889,20 L10.6825,20 L10.6825,12.255 L8.07611111,12.255 L8.07611111,9.23666667 L10.6825,9.23666667 L10.6825,7.01055556 C10.6825,4.42722222 12.2602778,3.02083333 14.5647222,3.02083333 C15.6686111,3.02083333 16.6172222,3.10277778 16.8938889,3.13972222 L16.8938889,5.83944444 L15.2955556,5.84027778 C14.0422222,5.84027778 13.7997222,6.43583333 13.7997222,7.30972222 L13.7997222,9.23694444 L16.7886111,9.23694444 L16.3994444,12.2552778 L13.7997222,12.2552778 L13.7997222,20 L18.8963889,20 C19.5058333,20 20,19.5058333 20,18.8961111 L20,1.10388889 C20,0.494166667 19.5058333,0 18.8961111,0 L18.8961111,0 Z"/>
                </svg>
              </a>
            </span>
          </div>
          <div className="member_gr">
            <img src={LogoShape} alt="No Alt" className='float-right' />
          </div>
        </div>
      </div>
    </div>

    <div className="copyright float-left">
    <p>Copyright © 2024 {contact?.ten_cong_ty_txt}. All rights reserved.</p>
  </div>
  </footer>
  );
};

export default Footer;
